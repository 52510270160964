// import CustomArray from './customArray';
// import CustomObject from './customObject';
// import CustomSlider from './customSlider';

const hidden = (props) => {

  return <div/>
}

function makeUiSchema(schema, settings, configuration){

  const { converters = [] } = settings || {};
  const [ converter = {} ] = converters;
  const { maxMPPTPower } = converter;
  const { converter: chosenConverter = '' } = configuration || {};
  let brand;

  if((typeof chosenConverter) === 'string'){
    ([brand, ] = chosenConverter.split(' '));
  }
  else{
    ({ brand } = chosenConverter)
  }

  const allowed = {
    "ui:order": [
      'converters',
      'pvArraysRetrofit',
      '*',
    ],
    nominalElectricityPrice: {
      /*"ui:widget": "range",
      "ui:options": {
        "step": 0.01
      }*/
    },
    ACPhaseCount: {
      //"ui:widget": "range",
    },
    hasP1: {
    },
    hasEastronMeter: {
    },
    isConverterSelfUseCapable: {
      "ui:title": "The inverter has a meter and can work standalone",
      "ui:description": "This installation can work safely and effectively without FlexiO",
    },
    converters: {
      "ui:title": "Converter Settings",
      "ui:field": hidden,
      items: {
        //"ui:ObjectFieldTemplate": CustomObject,
        //"ui:widget": "hidden"
        pvArrays:{
          "ui:title": "PV Panels",
          "ui:field": hidden,
          "ui:description": "Information of each installed solar panel ('+' adds; '-' removes)",
        },
        battery:{
          "ui:title": "Battery",
          packCount: {
            // "ui:widget": "range"
          },
          cellsPerPack: {
            // "ui:widget": "range"
          },
          cellCapacity: {
            // "ui:widget": "range"
          },
          batteryType: {
            "ui:widget": "hidden"
          },
          "cellMinVoltage": {
            "ui:widget": "hidden"
          },
          "cellRatedVoltage": {
            "ui:title": "Cell Nominal Voltage",
            // "ui:widget": "hidden"
          },
          "cellMaxVoltage": {
            "ui:widget": "hidden"
          },
          "cellMaxCurrent": {
            // "ui:widget": "hidden"
          },
          "balancingCurrent": {
            "ui:widget": "hidden"
          },
        },
        "maxACDCPower": {
          //"ui:widget": "range"
        },
        "maxBatteryPower": {
          //"ui:widget": "range"
        },
        "maxBatteryCurrent": {
          "ui:widget": "hidden"
        },
        "maxMPPTPower": {
          //"ui:widget": "range"
        },
        powerDraw: {
          "ui:widget": "hidden"
        }
      },
      "ui:title": "Converter",
      "ui:options": {
        removable: false
      }
    },
    solarInjectionFactor: {
      // "ui:widget": "range"
    },
    meterCurrentLimitation:{
      // "ui:widget": "range"
    },
    pvArraysRetrofit:{
      /*items: {
        "ui:widget": CustomObject
      },*/
      "ui:title": "Retrofit Panels",
      "ui:description": "Add existing solar instalation(s) ('+' adds; '-' removes)"
    },
    retrofitPVMeasurement: {

    },
    tariffStructure: {
      "ui:title": "Tariff",
      tariffType: {
        "ui:widget": "hidden"
      },
    },
    customInjectionTariffStructure: {
      "ui:title": "Specific tariff for injection",
    },
    /*EVChargers: {
      "ui:ArrayFieldTemplate": CustomArray,
      "ui:widget": "hidden"
    },
    loadForecaster: {
      "ui:ObjectFieldTemplate": CustomObject,
      "ui:widget": "hidden"
    },
    solarForecaster: {
      "ui:ObjectFieldTemplate": CustomObject,
      "ui:widget": "hidden"
    }*/
  };

  /*const uiSchema = Object.fromEntries(
    Object.entries(schema.properties || {}).map( ([k, v]) => {
      let toReturn
      const { [k]: custom } = allowed;

      if(custom) toReturn = [k, custom];
      else toReturn = [k, { "ui:widget": "hidden" }];

      return toReturn;
    })
  );
  console.log("RETURNING", uiSchema);*/
  if (maxMPPTPower) {
    delete allowed.converters.items.pvArrays['ui:field'];
  }
  if(!settings){
    delete allowed['ui:order'];
  }
  if(brand !== 'DefaultInverter'){
    delete allowed.converters['ui:field'];
  }

  return allowed;
}

const DEFAULT_SCHEMA_KEYS = [
  "tariffStructure",
  "customInjectionTariffStructure",
  "VAT",
  "dso",
  "hasDigitalMeter",
  "minimumStateOfCharge",
  "maximumStateOfCharge",
  "maximumReserveStateOfCharge",
  "latitude",
  "longitude",
  "injectionLimit",
  "meterPowerDeadband",
  "hasP1",
  "hasEastronMeter",
  "meterCurrentLimitation",
  "ACPhaseCount",
  "genericLoadMaximumElectricityPrice",
  "isConverterSelfUseCapable",
  "retrofitPVMeasurement",
  "doubleTariffVariant",
  "enableThreePhaseLoadBalancing",
  "useUnbiasedEstimators",
  "verboseEMSLogs",
  "shouldExportModelAfterEachOptimization",
  "pvArraysRetrofit",
  "converters",
  "EVChargers"
];

const USER_NONE_ADVANCED_SCHEMA_KEYS = [
  "tariffStructure",
  // "customInjectionTariffStructure"
];

/**
 * The function filters an object's properties based on a given array of keys to keep.
 * @returns an object that contains only the properties specified in the `keysToKeep` array.
 */
function filterSchemaProperties ({ properties = {} } = {}, keysToKeep) {
  return Object.entries(properties)
    .reduce((acc, [key, value]) => {
      if (keysToKeep.includes(key)) acc[key] = value;
      return acc;
    }, {});
}

export default { 
  makeUiSchema, filterSchemaProperties,
  DEFAULT_SCHEMA_KEYS,
  USER_NONE_ADVANCED_SCHEMA_KEYS,
};
