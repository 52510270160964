import React, { useEffect, useState, useMemo } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import CircularProgress from '@mui/material/CircularProgress';
import FilterListIcon from '@mui/icons-material/FilterList';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Alert from '@mui/material/Alert';

import { connect } from 'react-redux';

import { useLocation, useHistory } from "react-router";
import OtpInput from "react-otp-input-rc-17";

import Grid from '@mui/material/Grid';
import {Auth, API} from "Amplify.js";

import UserList from './UserList';
import FleetList from './FleetList';
import otpInputStyles from '../../utils/otp-input-styles';

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function FileButton(props){
	const { file, label } = props;
	const [loading, setLoading] = useState(false);
	const [url, setUrl] = useState();
	const [click, setClick] = useState(0);

	const onClick = () => {
		setClick(old => old + 1);
	};

	useEffect(
		() => {
			async function getUrl(){
				setUrl(null);
				setLoading(true);
				try{
					const result = await API.get("IO_API", `/files/releases/${file}`);
					const { url } = result;

					setUrl(url);
				}
				catch(err){
					console.log("ERROR", err);
					setUrl(null);
				}
				setLoading(false);

			}
			if(click) getUrl();
		},
		[click],
	);

	useEffect(
		() => {
			if(url){
				window.location.assign(url);
				setUrl(null);
			}
		},
		[url],
	);

	return (
		<Button disabled={loading} onClick={onClick}>
			{label} {loading ? <CircularProgress /> : null}
		</Button>
	);
}

function Fleets(props){

	const { admin: { admin = false } = {} } = props;
	/*const history = useHistory();
	const { fleet } = useParams();*/
	const [tab, setTab] = useState(0);
	const [req, setReq] = useState(0);
	const [users, setUsers] = useState(null);
	const [fleets, setFleets] = useState(null);
	const [userInfo, setUserInfo] = useState({});
	const [loadingUsers, setLoadingUsers] = useState(false);
	const [loadingFleets, setLoadingFleets] = useState(false);
	const [loadingImages, setLoadingImages] = useState(false);
	const [loadingImageURL, setLoadingImageURL] = useState(false);
	const [downloadImage, setDownloadImage] = useState(false);
	const [selectedImage, setSelectedImage] = useState('');
	const [selectedImageURL, setSelectedImageURL] = useState(null);
	const [filter, setFilter] = useState(false);
	const [displayList, setDisplayList] = useState(null);
	const [error, setError] = useState(false);
	const [images, setImages] = useState(false);
	const [imageList, setImageList] = useState([]);
	const [claim, setClaim] = useState(false);
	const [claimFleet, setClaimFleet] = useState('');
	const [claimCode, setClaimCode] = useState(null);
	const [claimError, setClaimError] = useState(null);
	const [claimThing, setClaimThing] = useState(null);
	const [claimReference, setClaimReference] = useState('');
	const [loadClaim, setLoadClaim] = useState(false);
	const query = useQuery();
	const queryClaim = query.get('claim');
	const queryCode = query.get('code');
	const history = useHistory();

	let /*title,*/ Component, componentProps, errorMsg, filterDisable, content, selectImages, imgSelect;

	const refresh = () => setReq(req + 1);

	const findObj = (tab, search) => {
		setDisplayList([]);
		setFilter(search);
		setTab(tab);
		setError(undefined);
	}

	const Components = { 
		"Fleets": {
			Component: FleetList,
			props: {admin, fleets: displayList, users, refresh, loading: loadingFleets, setError, setFleets, findObj},
			content: fleets,
			set: setFleets,
			loading: loadingFleets,
			filter: (initial, filterStr) => {
				if(!filterStr) return initial;
				const firstPass = initial.filter( fleet => fleet.thingGroupName.toLowerCase().includes(filterStr.toLowerCase()) );
				const parents = Array.from(new Set(firstPass.reduce( (acc, fleet) => ([...acc, ...(fleet.parentGroupNames || [])]), [] )));
				const secondPass = initial.filter( fleet => parents.includes(fleet.thingGroupName) );

				return [...firstPass, ...secondPass];
			}
		},
		"Users": {
			Component: UserList,
			props: {admin, users: displayList, fleets, refresh, loading: loadingUsers, setError, findObj},
			content: users,
			set: setUsers,
			loading: loadingUsers,
			filter: (initial, filterStr) => {
				if(!filterStr) return initial;
				return initial.filter(user => filterStr ? (user.userId.includes(filterStr) || user.email.includes(filterStr)) : true );
			}
		},
	};

	useEffect( () => {
		setLoadingFleets(true);
		setLoadingUsers(true);
		console.log('NOT HERE?!');
		API.get("IO_API", `/user/all`, { headers: {"Content-Type": "application/json"} })
			.then( res => {
				setLoadingUsers(false);
				setUsers(res);
			})
			.catch(err => {
				setLoadingUsers(false);
				setError(err.response);
			});
		API.get("IO_API", `/fleet/all`, { headers: {"Content-Type": "application/json"} })
			.then( res => {
				setLoadingFleets(false);
				const fleetsLocalAction = JSON.parse(window.localStorage.getItem('fleets')) || [];
				const newLocal = [];
				const reqFleets = res || [];

				fleetsLocalAction.forEach( fleet => {
					const {action, thingGroupName} = fleet;

					const index = reqFleets.findIndex( remoteFleet => remoteFleet.thingGroupName === thingGroupName);

					if(index < 0){
						if(action === 'post'){
							newLocal.push(fleet);
							reqFleets.push(fleet);
						}
					}
					else{
						if(action === 'del'){
							newLocal.push(fleet);
							reqFleets[index].action = action;
						}
					}
				});

				window.localStorage.setItem('fleets', JSON.stringify(newLocal));

				setFleets(reqFleets);
			})
			.catch(err => {
				setLoadingFleets(false);
				setError(err.response);
			});
	}, [req]);

	useEffect(() => {
		const tabObj = Object.entries(Components)[tab][1];
		const { content = [], filter: filterFun = () => content } = tabObj || {};

		setDisplayList(filterFun(content, filter));
	}, [users, fleets, filter, tab]);

	useEffect(
		() => {
			if(images){
				setLoadingImages(true);
				API.get("IO_API", `/files/releases`, { headers: {"Content-Type": "application/json"} })
					.then(res => {
						setImageList(res);
					})
					.catch(e => {
						console.log("GOT ERROR", e);
					})
					.finally( () => {
						setLoadingImages(false);
					});
			}
			else{
				setImageList([]);
				setSelectedImage('');
			}
		},
		[images],
	);

	useEffect(
		() => {
			if(downloadImage && selectedImageURL){
				window.location.assign(selectedImageURL);
				setDownloadImage(false);
			}
		},
		[downloadImage, selectedImageURL],
	);

	useEffect(
		() => {
			if(selectedImage) {
				setLoadingImageURL(true);
				API.get("IO_API", `/files/releases/${selectedImage}`, { headers: {"Content-Type": "application/json"} })
					.then( ({url}) => setSelectedImageURL(url))
					.catch(e => console.log("ERROR", e))
					.finally( () => setLoadingImageURL(false));
			}
		},
		[selectedImage]
	);

	useEffect(
		() => {
			setClaim(Boolean(queryClaim));
			setClaimCode(queryCode);
		},
		[queryClaim, queryCode],
	);

	const onChange = (ev, newValue) => {
		setDisplayList([]);
		setFilter('');
		setTab(newValue);
		setError(undefined);
	}

	const tabs = Object.entries(Components).map( ([key, val], idx) => {
		if(tab === idx){
			//title = key;
			({ Component = null, props : componentProps = {}, loading: filterDisable } = val || {});
		}

		return <Tab label={key} key={key} id={idx} />;
	});

	if(error){
		errorMsg = `${error.data.body || error.data}`
	}

	if(Component){
		const filterProps = {
		};
		content = (
			<div>
				<Grid container spacing={0}>
					<Grid item>
						<FilterListIcon />
					</Grid>
					<Grid item>
						<TextField value={filter || ''} onChange={(ev) => setFilter(ev.target.value)} disabled={filterDisable} {...filterProps} />
					</Grid>
				</Grid>
				<Component {...componentProps}/>
			</div>
		);
	}

	if(admin){
		selectImages = imageList.map(({value, label}) => <MenuItem key={value} value={value}>{label}</MenuItem>)
		imgSelect = (imageList.length > 0 ? 
			<FormControl fullWidth variant="standard">
				<InputLabel>Build Image</InputLabel>
				<Select value={selectedImage} onChange={(ev, v) => setSelectedImage(ev.target.value)}>{selectImages}</Select>
			</FormControl>
			: <div>No images found</div>
		);
		/*images = (
			<ButtonGroup orientation="vertical" style={{float: 'right'}}>
				{
					files.map(
						([file, label]) => <FileButton file={file} label={label} key={label} />
					)
				}
			</ButtonGroup>
		);*/

		/*(
			<div style={{float: 'right'}}>
				<form method="get" action="lifepowr.sdimg.bz2">
					<button type="submit">Download Image</button>
				</form>
				<form method="get" action="lifepowr_dev.sdimg.bz2">
					<button type="submit">Download Dev Image</button>
				</form>
				<form method="get" action="lifepowr_nightly.sdimg.bz2">
					<button type="submit">Download NIghtly Image</button>
				</form>
			</div>
		);*/
	}
	else{ // Partners are confused
		// tabs.pop();
	}

	useEffect(
		() => {
			async function request(){
				if(loadClaim) {
					setClaimError(null);
					try{
						const { thingName } = await API.post("IO_API", `/fleet/device-claim`, { body: { code: claimCode, fleetName: claimFleet, reference: claimReference } });
						setClaimThing(thingName);
					} catch(e) {
						setClaimError(e?.response?.data || e.message || 'Unidentified error');
					}
					setLoadClaim(false);
				}
			}
			request();
		},
		[loadClaim]
	)

	const codeChange = (ev) => {
		const newCode = ev.replaceAll(/[^a-zA-Z0-9]/g,"").toUpperCase();
		setClaimCode(newCode);
	}

	const claimDialogClick = () => {
		setClaim(true);
		setClaimCode('');
		setClaimFleet('');
		setClaimError(null);
	}

	const claimDialogLeave = () => {
		setClaim(false);
		setClaimCode('');
		setClaimFleet('');
		setClaimError(null);
		history.replace({search: ''});
	}

	let alert;
	let alertType;
	let alertAction=<></>;

	if (claimThing) {
		alertType = 'success';
		alert = `Successfully claimed device '${claimThing}'`;
		alertAction = (
			<Button onClick={() => {history.push(`${claimFleet}/devices/${claimThing}/device-details?wizard=true`)}}>
				Config Wizard
			</Button>
		);
	} 
	if (claimError) {
		alertType = 'error';
		alert = `${claimError}`;
	}
	if (loadClaim) {
		alertType = 'info';
		alert = 'Claiming. . .'
	}

	return (
		<React.Fragment>
			<div style={{height: '90%', overflowY: 'auto', display: 'grid', gridTemplateColumns: '1fr', gridTemplateRows: 'minmax(20px, 100px) minmax(300px, 1fr) minmax(20px, 100px)'}}>
				<Tabs style={{margin: "auto"}} value={tab} onChange={onChange}>
					{tabs}
				</Tabs>
				{ content }
				{ errorMsg ? <div>{errorMsg}</div> : null }
			</div>
			<div>
				<Button style={{float: 'right'}} onClick={claimDialogClick}>Claim device</Button>
				{ admin ? <Button style={{float: 'right'}} onClick={() => setImages(true)}>Images</Button> : null }
			</div>
			<Dialog
				open={claim}
				onClose={claimDialogLeave}
			>
				<DialogTitle>
					Device Claim
				</DialogTitle>
				<DialogContent>
					{
						alert && <Alert severity={alertType} action={alertAction}>{alert}</Alert>
					}
					<FormControl fullWidth variant="standard">
						<InputLabel>Select fleet</InputLabel>
						<Select value={claimFleet} onChange={({target}) => setClaimFleet(target.value)}>
							{ (fleets || []).map(({thingGroupName, thingGroupId}) => <MenuItem key={thingGroupId} value={thingGroupName}>{thingGroupName}</MenuItem>) }
						</Select>
					</FormControl>
					<Divider/>
					<Typography variant='subtitle2'>Claim Code</Typography>
					<OtpInput value={claimCode} numInputs={6} onChange={codeChange} containerStyle={otpInputStyles.container} inputStyle={otpInputStyles.inputStyle} />
					<Divider/>
					<TextField label="Reference (optional)" onChange={(e) => setClaimReference(e.target.value)}variant="standard" />

				</DialogContent>
				<DialogActions>
					<Button disabled={!(claimCode && claimFleet && claimCode.length >= 6 && !loadClaim)} onClick={() => setLoadClaim(true)}>Claim</Button>
					<Button onClick={() => setClaim(false)}>Cancel</Button>
				</DialogActions>
			</Dialog>
			<Dialog
				onClose={() => setImages(false)}
				open={images}
			>
				<DialogTitle>Download Images</DialogTitle>
				<DialogContent>
					{ loadingImages ? <CircularProgress/> : imgSelect }
				</DialogContent>
				<DialogActions>
					<Button disabled={!Boolean(selectedImage)} onClick={() => setDownloadImage(true)}>{ (loadingImageURL ? <CircularProgress/> : 'Download') }</Button>
					<Button onClick={() => setImages(false)}>Cancel</Button>
				</DialogActions>
			</Dialog>
		</React.Fragment>
	);
}


const mapStateToProp = (state) => {
    const {admin} = state
    return { admin };
}

export default connect(mapStateToProp, null)(Fleets);