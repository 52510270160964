import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import DialogTitle from '@mui/material/DialogTitle';
import Avatar from '@mui/material/Avatar';
import Badge from '@mui/material/Badge';
import Paper from '@mui/material/Paper';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Alert from '@mui/material/Alert';

import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import CastConnectedIcon from '@mui/icons-material/CastConnected';
import CloudIcon from '@mui/icons-material/Cloud';
import EvStationIcon from '@mui/icons-material/EvStation';

import { merge } from "lodash";

import useSocket from '@lifepowr/components/src/components/socket/useSocket';

import OCPPComponent from "./ocpp";
import Charger, { ExistingCharger } from "./Charger";
import brands from "./brands";
import Unsupported from "./unsupported.png";
import './styles.scss';

import * as chargerCreators from "@lifepowr/components/src/store/chargers";

const { default: def, ...chargerActionCreators } = chargerCreators;

function BrandCard(props){
	const {action = () => {}, title, steps, Icon, count} = props;
	const brand = steps ? title : `${title} - Not supported`;

	const badgeProps = {
		color:"primary",
		badgeContent: undefined,
		invisible: count < 1
	};

	return (
		<Badge {...badgeProps}>
			<Card className="ev-charger-brand">
				<CardActionArea onClick={action}>
					<CardMedia
						component="img"
						image={Icon}
						title={`${title} icon`}
						className="ev-charger-brand-media"
					/>
				<CardContent>
					<Typography>
						{brand}
					</Typography>
				</CardContent>
				</CardActionArea>
			</Card>
		</Badge>
	);
}

function Steps(props){
	const { setError, steps, cancel, thingName, title, submit, chargers, setChargers, ...rest } = props;
	const [ activeStep, setActiveStep ] = useState(0);
	const [ buttonTitle, setButtonTitle ] = useState("Next");
	const [ buttonDisabled, setButtonDisabled ] = useState(false);
	const [ choices, setChoices ] = useState({});
	const [ loading, setLoading ] = useState(false);
	const [ currentStep, setCurrentStep ] = useState({});
	const { Component, confirm, chooser } = currentStep || {} ;

	useEffect( () => {
		if(steps) setCurrentStep(steps[activeStep]);
	}, [steps, activeStep]);

	const compProps = {
		buttonTitle, setButtonTitle, buttonDisabled, setButtonDisabled, chargers, setChargers, Charger, choices, setChoices, thingName, ...rest, setLoading, setError, loading
	};

	const handleBack = () => {
		if(activeStep <= 0){
			setActiveStep(0);
			cancel();
		}
		else setActiveStep(activeStep - 1);
	};

	const handleNext = async () => {
		
		const { action = () => {} } = currentStep || {} ;

		try{
			setLoading(true);
			setError(null);
			const brandObj = await action({choices, setChoices, ...props});

			if(activeStep >= steps.length - 1){
				//Gotta submit
				if(submit  && title) await submit(title.toLowerCase(), brandObj);
			}
			else{
				setActiveStep(old => {
					return (old + 1);
				});
			}
		}
		catch(e){
			const body = e?.data?.body || `${e}`;
			setError(`${body}`);
			console.log("ERROR", body, e);
		}
		setLoading(false);
	};

	let render = null;

	if(loading) render = (<div className="ev-brand-cards">Loading . . .</div>);
	else if(Component) render = (<Component {...compProps}/>);

	return (
		<>
			<Stepper activeStep={activeStep}>
				{
					steps.map( ({label}) => <Step key={label}><StepLabel>{label}</StepLabel></Step>)
				}
			</Stepper>
			{ render }
			<div>
				<Button disabled={loading} onClick={handleBack}>Back</Button>
				<Button disabled={buttonDisabled || loading} onClick={handleNext}> {buttonTitle}</Button>
			</div>
		</>
	);
}

function ChargerComms(props){
	const [ev, setEv] = useState({});
	const [newCharger, setNewCharger] = useState(false);
	const [selectedCharger, setSelectedCharger] = useState(null);
	const [choice, setChoice] = useState(null);
	const [loading, setLoading] = useState(false);
	const [ success, setSuccess ] = useState('');
	const [error, setError] = useState('');
	const [retry, setRetry] = useState(0);
	const [dialog, setDialog] = useState('EV Charger')
	const { subscribe, publish, unsubscribe } = useSocket();
	const { thingName, connected } = props?.system || {};
	let render;

	const submit = async (brand, obj, expire = 0) => {
		const prefix = `fleet/devices/${thingName}`;
		const topic = `${prefix}/state/interact`;
		const pubObj = {task:'ev', args: { type: 'set', expire }};
		let repEv;

		setEv( old => {
			const {[brand]: brandObj} = old;
			const newObj = merge(old, { [brand] : obj});

			if(!obj) delete newObj[brand];
			return newObj;
		});
		pubObj.args.data = ev;

		setLoading(true);
		setError('');
		try{
			if(!brand) throw new Error('Undefined data provided');


			repEv = await publish(topic, pubObj, true, 10000);

			setEv(repEv);
			setSuccess("Your device's EV configuration has been successfully updated!");
		}
		catch(e){
			setError(`An error ocurred: ${e}`);
		}
		setLoading(false);
	}

	const submitCharger = async (brand, id, obj, expire) => {
		const prefix = `fleet/devices/${thingName}`;
		const {brand: selectedBrand, id: selectedId} = selectedCharger;
		const topic = `${prefix}/state/interact`;
		const pubObj = {task:'ev', args: { type: 'setSingle', expire }};

		setEv(old => {
			const newObj = merge(old, { [brand]: { [id]: obj } });

			if(!obj){
				delete newObj[brand][id];
			} 
			return newObj;
		});
		await submit(brand, ev[brand], expire);
		if(!obj && selectedBrand === brand && Number(selectedId) === id) setSelectedCharger(null);
	}

	useEffect(
		() => {
			const timer = setInterval(() => setRetry(o => (o + 1)), 15000);
			return () => clearTimeout(timer);
		},
		[],
	);

	useEffect(
		()=>{
			const { brand, id } = selectedCharger || {};

			if (brand) {
				const { [brand]: brandChargers = {} } = ev || {};
				const { [id]: chg } = brandChargers;
				if (!chg) {
					setSelectedCharger(null);
				}
			}
		},
		[ev, selectedCharger]
	)

	useEffect( () => {
		async function get(){
			const prefix = `fleet/devices/${thingName}`;
			const topic = `${prefix}/state/interact`;
			const pubObj = {task:'ev', args: {type: 'get'}};
			let evData;

			setLoading(true);
			setError('');
			try{
				evData = await publish(topic, pubObj, true);
				setEv(evData);
			}
			catch(e){
				setError(`Could not collect info, reason: ${e}`);
			}
			setLoading(false);

			return ev;
		}
		if(connected) get();
	}, [connected, retry, choice]);

	useEffect( () => {
		if(!newCharger && !selectedCharger) setDialog('EV Chargers');
		setSuccess('');
		setError('');
	}, [newCharger, selectedCharger])

	useEffect(
		() => {
			if(loading){
				setSuccess('');
				setError('');
			}
		},
		[loading]
	)

	const comps = [OCPPComponent, EvComponent];
	const { brand, id } = selectedCharger || {};
	const { [brand] : { [id]: currentCharger } = {} } = ev;
	const Component = choice !== null ? comps[choice] : null;
	const action = (number) => {
		return (ev) => {
			setChoice(number);
		}
	}

	const compProps = { submitCharger, setSelectedCharger, ev, setEv, loading, setLoading, error, setError, dialog, setDialog, setNewCharger, setSuccess, ...props };
	const chargerProps = {
		brand,
		id,
		charger: currentCharger,
		submitCharger,
		loading,
		...props
	}

	if (!connected) {
		render = (
			<div className="ev-brand-cards">Device offline</div>
		);
	} else if (newCharger) {
		render = (
			<EvComponent {...compProps}/>
		);
	} else if (currentCharger) {
		render = (
			<ExistingCharger {...chargerProps}/>
		);
	} else {
		render = loading ? (<div className="ev-brand-cards">Loading . . .</div>) : (<ChargersList {...compProps} />);
	}

	return (
		<div className="ev-brand-wrapper">
			{ success ? <Alert onClose={() => setSuccess('')} severity="success">{success}</Alert> : null }
			{ error ? <Alert onClose={() => setError('')} severity="error">{error}</Alert> : null }
			<DialogTitle className='ev-title'>{dialog}</DialogTitle>			
			{ render }
			<div>
				<Button style={{ float: 'right' }} onClick={() => {setNewCharger((newCharger || Boolean(selectedCharger)) ? false : !newCharger );setSelectedCharger(null) }}>
					{ (newCharger || Boolean(selectedCharger)) ? (Boolean(selectedCharger) ? 'Back' : 'Cancel') : 'Add Charger' }
				</Button>
			</div>
		</div>
	);

}

function ChargersList(props){
	const { ev = {}, setSelectedCharger = () => {}, setDialog = () => {} } = props;
	const cards = [];

	let render;

	Object.entries(ev).forEach(
		([brand, chargers]) => {
			Object.entries(chargers).forEach( ([id, charger]) => {
				const { label } = charger;
				const cardProps = {
					id,
					key: id,
					brand,
					charger,
					label,
					select: () => {
						setDialog(label || 'Unnamed charger');
						setSelectedCharger({id, brand});
					}
				};

				if(!id.includes('@') && !(brand === 'ocpp') && !(id === 'modbus')){
					cards.push(
						<ChargerCard {...cardProps}/>
					);
				}
			});
		},
	);

	if ( cards.length < 1 ){
		render = (
			<div>No chargers found</div>
		);
	}
	else {
		render = cards
	}

	return (
		<div className='ev-chargers-list'>
			{render}
		</div>
	);
}

function ChargerCard(props){
	const { id, brand, charger, select = () => {} } = props;
	const { label, enabled, status = -1, diagnostic = {} } = charger;
	const { status: statusDiag = true } = diagnostic || {};
	const { Icon } = brands[brand] || {};
	const action = () => {
		select();
	};
	const good = enabled && status >= 0 && statusDiag

	const border = `1px solid ${good ? "green" : "red"}`;
	const cardStyle={
		boxShadow: 12,
		border,
	}

	return (
		<Card sx={cardStyle} className="ev-charger-brand">
			<CardActionArea onClick={action}>
				{ Icon ?
					<CardMedia
						component="img"
						image={Icon}
						title={`${id} icon`}
						className="ev-charger-brand-media"
					/> :
					<EvStationIcon className="ev-charger-brand-media" />
				}
				<CardContent>
					<Typography>
						{label}
					</Typography>
				</CardContent>
			</CardActionArea>
		</Card>	
	);
}

function EvComponent(props){
	const { setNewCharger, setSuccess, chargers, setChargers, ev, setEv, loading, setLoading, error, setError, dialog, setDialog } = props;
	const { thingName } = props?.system || {};
	const [ chosen, setChosen ] = useState({});
	
	const { subscribe, publish, unsubscribe } = useSocket();
	const manualBrands = Object.fromEntries(
		Object.entries(brands).filter(([b, o]) => !o.auto),
	);
	let { title, steps, ...chosenProps } = chosen;
	let dialogTitle = "EV Charger";
	let render;

	const cancel = () => setChosen({});

	const cards = Object.entries(manualBrands).map( ([k, { Component, Icon, title, count, steps = [] }]) => {
		const {[k.toLowerCase()] : state = {} } = ev;
		const chargerCount = count ? count(state) : 0;

		const cardProps = { 
			key: k, title: title || k, Icon, action: () => setChosen({Component, state, cancel, title: k, steps}), count: chargerCount, steps
		};

		return <BrandCard {...cardProps}/>
	});

	const submit = async (brand, obj) => {
		const prefix = `fleet/devices/${thingName}`;
		const topic = `${prefix}/state/interact`;
		const pubObj = {task:'ev', args: { type: 'set' }};
		let repEv;

		setEv( old => {
			const {[brand]: brandObj} = old;
			const newObj = merge(old, { [brand] : obj});

			if(!obj) delete newObj[brand];
			return newObj;
		});
		pubObj.args.data = ev;

		setLoading(true);
		try{
			if(!brand) throw new Error('Undefined data provided');

			repEv = await publish(topic, pubObj, true);
			setEv(repEv);
			setSuccess("Your device's EV configuration has been successfully updated!");
			setNewCharger(false);
		}
		catch(e){
			setError(`Could not set state, reson: ${e}`);
		}
		setLoading(false);

		setChosen({});
	}

	const stepsProps = {...chosenProps, submit, thingName, setError, cancel, steps, setLoading, title, chargers, setChargers};

	useEffect( () => {
		setDialog('New Charger');
	} , []);

	if(!Boolean(steps)) render = (
		<div className="ev-brand-cards">
			{ cards.length > 0 ? 
				cards :
				<div>No chargers linked</div> 
			}
		</div>
	);
	else render = (
		<Steps {...stepsProps}/>
	);

	return (
		<div>
			{ render }
		</div>
	);
}

export default connect((state) => ({ system: state.system }), null, null)(ChargerComms);
