
import React, { useEffect, useState } from "react";
import './user-display.styles.scss';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Switch from '@mui/material/Switch';
import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import SettingsIcon from '@mui/icons-material/Settings';
import DescriptionIcon from '@mui/icons-material/Description';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import EmailIcon from '@mui/icons-material/Email';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

import { connect } from 'react-redux';

import { ReactComponent as Chevron } from '@lifepowr/components/src/assets/icons/icon-chevron-down.svg';
import { Auth, API } from "Amplify";
import { useHistory } from "react-router-dom";

import validator from '@rjsf/validator-ajv8';
import Form from '@rjsf/mui';

import { validateIBAN, isValidIBAN } from 'ibantools';

import userDataSchema from "./userDataSchema";
import partnerDataSchema from "./partnerDataSchema";

import * as globalCreators from "@lifepowr/components/src/store/global";

import getClientInfo from "../../clients";

import { useTerms } from "../../utils/terms";

const { default: df, ...globalActionCreators } = globalCreators;
/**
 * This class renders the current logged userName obtained from Auth
 * At this moment the user has no userName defined so the attribute email is being used.
 * The component is rendered with a chevron and a colapsable menu in order to perform log out
 */
function UserDisplay(props) {
    const { userMode, setGlobal, global: globalSettings, adminStore } = props;
    const { advanced } = globalSettings;
    const { admin } = adminStore;
    const [userAttrs, setUserAttrs] = useState(null);
    const [formData, setFormData] = useState(null);
    const [open, setOpen] = useState('');
    const [anchor, setAnchor] = useState(null);
    const [settings, setSettings] = useState(false);
    const [firstLoad, setFirstLoad] = useState(true);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [badVersion, setBadVersion] = useState(false);
    const [acceptNewVersion, setAcceptNewVersion] = useState(false);
    const [ibanOpen, setIbanOpen] = useState(false);
    const [manualTou, setManualTou] = useState(false);
    const history = useHistory();
    const terms = useTerms();

    const schema = userMode ? userDataSchema : partnerDataSchema;

    let clientInfo = getClientInfo();

    const showSettingsFederated = clientInfo ? clientInfo?.settings : true;
    const showIbanFederated = clientInfo ? clientInfo?.iban : true;
    const showTermsFederated = clientInfo ? clientInfo?.tou : true;
    const allowAdvancedMode = clientInfo ? clientInfo?.allowAdvancedMode : true;
    const redirectSignOut = clientInfo ? clientInfo.signOut : '';

    useEffect(
        () => {
            if (userAttrs) {
                setFormData(
                    (old) => Object.fromEntries(
                        Object.entries(userAttrs).map(([k, v]) => {
                            let value = v;
                            let key = k;
                            console.log()
                            if (k === 'ibanTs') {
                                key = 'confirmIban',
                                value = true;
                            }
                            else {
                                try {
                                    value = JSON.parse(v);
                                } catch (e) {}
                            }
                            return [key, value];
                        })
                    ),
                );
                const { iban, ibanTs } = userAttrs;
                setIbanOpen((!iban || !ibanTs) && showIbanFederated);
                if(userMode && terms && showTermsFederated) {
                    if( terms.version && terms.version !== userAttrs.tocversion) {
                        setBadVersion(true);
                    } else setBadVersion(false);
                }
            }
        },
        [userAttrs, terms, userMode, firstLoad],
    );

    useEffect( () => {
        if(firstLoad) Auth.currentUserInfo()
        .then(user => {
            const { attributes = {} } = user || {};
            const newAttrs = Object.fromEntries(
                Object.entries(attributes).map(([k,v]) => [k.split(':').pop(), v]),
            );
            setUserAttrs(newAttrs);
            setFirstLoad(false);
        })
        .catch(err => {
            console.error('User could not been retrieved ' +  err.log)
            setFirstLoad(false);
        });
    }, [firstLoad]);

    const handleLogOut = async () => {
        try{
            await Auth.signOut();
            if (!redirectSignOut) history.push('/');
        }
        catch(e){
            console.log("ERROR SIGNING OUT", e);
        }
    };

    const handleOpen = (ev) => {
        setAnchor( ev.currentTarget );
    };

    const handleClose = () => {
        setAnchor(null);
        setSettings(false)
        setOpen('');
    }

    const setAdvanced = () => setGlobal({advanced: !advanced});

        {/*<div className='user-display'>
            <span>Hello, </span><span className='user-display__name'>{username}</span>
            <span className='user-display__chevron' onClick={() => setIsHidden(!isHidden )}>
                <Chevron />
            </span>
            {
                !isHidden &&
                <div className='user-display__menu'>
                    <span>Hello, </span><span className='user-display__name'>{username}</span>
                    <span className='user-display__menu-chevron' onClick={() => setIsHidden( !isHidden )}>
                        <Chevron />
                    </span><br/>
                    { userMode ? <><span>User Mode</span><br/></> : null }
                    <span className='user-display__menu__logout text-right pr-2 pt-2' onClick={handleLogOut}>Log Out </span>
                </div>
            }
        </div>*/}
    const openMenu = (type) => {
        setOpen(open === type ? '' : type);
    }

    const onSubmit = (ev, ...rest) => {
        const { formData = {} } = ev;
        const body = Object.fromEntries(
            Object.entries(formData).map(([k, v]) => {
                const toIgnore = ['confirmIban'];
                if (toIgnore.includes(k)) return null;
                if ((typeof v) !== 'string') return [k, JSON.stringify(v)];
                return [k, v];
            }).filter(v => v),
        );
        console.log('WHAT?!', body);
        setLoading(true);
        API.put("IO_API", `/user`, { body })
        .then(
            r=>{
                setFirstLoad(true);
                handleClose();
            }
        )
        .catch(
            (e)=>{
                setError(e?.response?.data || `${e}`);
            }
        )
        .finally(
            () => {
                setLoading(false);
            }
        );
    }

    function onChange(ev){
        const { formData } = ev;
        setError(null);
        setFormData(formData);
    }

    const submitNewVersion = () => {
        setLoading(true)
        API.put("IO_API", `/user`, { body: {tocversion: terms.version} })
        .finally(
            () => {
                setFirstLoad(true);
                setLoading(false);
            }
        );
    }

    const scrolling = (e) => {
        const bottom = e.target.scrollHeight - e.target.scrollTop < e.target.clientHeight * 1.2;
        if(bottom) setAcceptNewVersion(true);
    }

    const { firstName, email = '', iban, ibanTs } = userAttrs || {};
    const username = firstName ? firstName : email.split('@')[0];

    function customValidate(data, errors, uiSchema){
        if (data.iban) {
            const ibanStr = (typeof data.iban) === "number" ? data.iban.toString() : data.iban;
            if (!isValidIBAN(ibanStr)) {
                errors.iban.addError('Invalid IBAN');
                setError('Invalid IBAN');
            }
        }
        if (!data.confirmIban) {
            errors?.confirmIban?.addError('Must confirm');
            setError('Must confirm IBAN');
        }
        return errors;
    }

    const schemaIban = {
      "type": "object",
      "required": [
      ],
      "properties": {
        "iban": {
          "type": "string",
          "title": "IBAN",
          "description": "Bank account for payouts"
        },
        "confirmIban": {
          "type": "boolean",
          "title": "I give LIFEPOWR permission to start a direct debit with this account number for energy control by FlexiO as soon as the free period has expired"
        }
      },
    }
    const { iban: testIban, confirmIban } = formData || {};
    const submitDisabled = !(Boolean(testIban) && Boolean(confirmIban));

    const menu = (
        <List>
            { showSettingsFederated ? <ListItemButton onClick={() => setSettings(true)}>
                <ListItemIcon>
                    <SettingsIcon/>
                </ListItemIcon>
                <ListItemText>
                    Settings
                </ListItemText>
            </ListItemButton> : null }
            { userMode ? <>
                { showTermsFederated ?
                    <ListItemButton onClick={() => setManualTou(true)}>
                        <ListItemIcon>
                            <DescriptionIcon/>
                        </ListItemIcon>
                        <ListItemText>
                            Terms
                        </ListItemText>
                    </ListItemButton>
                    : null }
                { allowAdvancedMode ?
                    <MenuItem onClick={setAdvanced}>
                        <div>
                            Advanced Mode
                            <Switch checked={advanced} color="primary" />
                        </div>
                    </MenuItem>
                    : null }
            </> :
            <>
                <ListItemButton onClick={() => openMenu('contact')}>
                    <ListItemIcon>
                      <PermContactCalendarIcon />
                    </ListItemIcon>
                    <ListItemText primary="Contact" />
                    {open === 'contact' ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={open === 'contact'} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItemButton onClick={() => window.open('https://wa.me/message/2TWAJMBB6Y5IA1', '_blank', 'noopener,noreferrer')}>
                            <ListItemIcon>
                                <WhatsAppIcon/>
                            </ListItemIcon>
                            <ListItemText>
                                Whatsapp
                            </ListItemText>
                        </ListItemButton>
                        {/*<ListItemButton onClick={() => window.open('mailto:hi@lifepowr.co')}>
                            <ListItemIcon>
                                <EmailIcon/>
                            </ListItemIcon>
                            <ListItemText>
                                Email
                            </ListItemText>
                        </ListItemButton> */}
                    </List>
                </Collapse>
            </> }
        </List>
    );

    let ibanTitle = 'Provide your IBAN to receive payouts';
    if (Boolean(iban) && !ibanTs) ibanTitle = 'Please confirm your IBAN'

    return (
        <>
            <Button style={{textTransform: 'none', fontSize: '1em', minHeight: 0, minWidth: 0, padding: 0}} aria-controls="simple-menu" aria-haspopup="true" onClick={handleOpen}>
                <div>Hello, <span className='user-display__name'> {username} </span> <span className='user-display__chevron'><Chevron /></span></div>
            </Button>
            <Menu
                id="simple-menu"
                anchorEl={anchor}
                keepMounted
                open={Boolean(anchor)}
                onClose={handleClose}
            >
                <MenuItem disabled>
                    <div>Hello, <span className='user-display__name'>{username}</span> </div>
                </MenuItem>
                {menu}
                <MenuItem onClick={handleLogOut}>
                    <div>Log Out</div>
                </MenuItem>
            </Menu>
            <Dialog
                open={settings}
                onClose={handleClose}
            >
                <DialogTitle>
                    User Settings
                </DialogTitle>
                <DialogContent>
                    <Form showErrorList={false} extraErrors={error ? { Error: { '__errors': [error] } } : {}} formData={formData} disabled={loading} onSubmit={onSubmit} validator={validator} liveValidate={true} onChange={onChange} customValidate={customValidate} omitExtraData={true} schema={schema} omitExtraData liveOmit />
                </DialogContent>
            </Dialog>
            <Dialog
                open={Boolean(userMode) && ibanOpen}
                onClose={close}
            >
                <DialogTitle>
                    {ibanTitle}
                </DialogTitle>
                <DialogContent>
                    <Form showErrorList={false} extraErrors={error ? { Error: { '__errors': [error] } } : {}} formData={formData} disabled={loading} onSubmit={onSubmit} validator={validator} liveValidate={true} onChange={onChange} customValidate={customValidate} omitExtraData={true} schema={schemaIban} omitExtraData />
                </DialogContent>
            </Dialog>
            <Dialog
                open={badVersion || manualTou}
                onClose={()=>{if(manualTou) setManualTou(false)}}
                scroll='paper'
            >
                <DialogTitle>
                    { manualTou ? 'Terms and conditions' : 'We have updated our terms and conditions' }
                </DialogTitle>
                <DialogContent onScroll={scrolling}>
                    <div style={{paddingLeft: '35px'}} dangerouslySetInnerHTML={{__html: (badVersion || manualTou) ? terms.terms : null}}></div>
                </DialogContent>
                <DialogActions>
                    { badVersion? <Button disabled={!acceptNewVersion || loading} onClick={submitNewVersion}>I have read and accept</Button> : null }
                    { manualTou? <Button onClick={()=>setManualTou(false)}>Close</Button> : null }
                </DialogActions>
            </Dialog>
        </>
    );
}

export default connect(
    (state) => {
        const global = state?.global;
        const adminStore = state?.admin;
        return {global, adminStore};
    },
    { ...globalActionCreators },
    null
)(UserDisplay);
